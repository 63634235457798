import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/landing/TextMaxLine';
import { fPercent, fCurrency } from 'src/utils/format-number';
import { Controller, useFormContext } from 'react-hook-form';
import Link from '../../components/link/link';
import { useResponsive } from '../../hooks/use-responsive';

// ----------------------------------------------------------------------

export default function EcommerceCheckoutOrderSummary({
                                                        client,
                                                        tax,
                                                        total,
                                                        subtotal,
                                                        shipping,
                                                        discount,
                                                        products,
                                                        loading,
                                                        step,
                                                        disabled,
                                                      }) {
  const { control } = useFormContext();
  const mdUp = useResponsive('up', 'md');

  const contactEmail = client?.contact_email || 'sales@beermybrand.com';

  return (
    <Stack spacing={3} sx={{ height: '100%', justifyContent: 'space-between' }}>

      {step > 0 && mdUp && <Stack
        spacing={3}
        sx={{
          p: 5,
          borderRadius: 2,
          border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
        }}
      >
        <Typography variant="h5"> Design Tips </Typography>
        <Typography variant="body1">To make a good design, consider these points: </Typography>
        <Typography variant="body1"> - <Link href="#">Check Examples</Link> <br /> - <Link href="#"> Download .psd example </Link> <br /> - <Link
          href="#">Setup project on
          Canva</Link> <br /> - <Link href="#">Verify design</Link> <br />
        </Typography>
        <Typography variant="body1">Upload Design and you are ready!</Typography>
        <Typography variant="body1">If you have any questions, contact us at <Link href={`mailto:${contactEmail}`} target="_blank"
                                                                                   rel="noopener">{contactEmail}</Link></Typography>
      </Stack>}

      {step > 4 &&
        <Stack
          spacing={3}
          sx={{
            p: 5,
            borderRadius: 2,
            border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
          }}
        >
          <Typography variant="h5"> Order Summary </Typography>

          {!!products?.length && (
            <>
              {products.map((product) => (
                <ProductItem key={product.id} product={product} />
              ))}

              <Divider sx={{ borderStyle: 'dashed' }} />
            </>
          )}

          {false && <Stack spacing={2}>
            <Row label="Brand Design" value="Free" valueSx={{ color: 'primary.main' }} />

            <Row label="400 x 0,33 l can" value={fCurrency(subtotal)} />

            <Row label="3 - 6 month delivery" value={fCurrency(shipping)} />

            {/* <Row label='Discount (15%)' value={`-${fCurrency(discount)}`} /> */}

            <Row label="Tax" value={fPercent(tax)} />
          </Stack>}

          <Controller
            name="orderAmount"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                hiddenLabel
                placeholder="Order Amount"
                variant="filled"
                inputProps={{
                  style: { fontSize: '1rem', lineHeight: 1, paddingLeft: 16 },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" variant="body2" disableTypography>
                      pcs
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />


          <Controller
            name="discountCode"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                hiddenLabel
                placeholder="Discount Code"
                variant="filled"
                inputProps={{
                  style: { fontSize: '1rem', lineHeight: 1, paddingLeft: 16 },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button color="primary">Apply</Button>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <Divider sx={{ borderStyle: 'dashed' }} />

          {false && <Row
            label="Total"
            value={fCurrency(total)}
            sx={{
              typography: 'h6',
              '& span': { typography: 'h6' },
            }}
          />}

          <LoadingButton
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            loading={loading}
            disabled={disabled}
            endIcon={
              <Iconify icon="solar:double-alt-arrow-right-bold" width={24} sx={{ mr: -0.5 }} />
            }
          >
            Order Now
          </LoadingButton>
        </Stack>
      }
    </Stack>
  );
}

EcommerceCheckoutOrderSummary.propTypes = {
  client: PropTypes.object,
  discount: PropTypes.number,
  loading: PropTypes.bool,
  products: PropTypes.array,
  shipping: PropTypes.number,
  subtotal: PropTypes.number,
  tax: PropTypes.number,
  total: PropTypes.number,
  step: PropTypes.number,
  disabled: PropTypes.bool,
};

// ----------------------------------------------------------------------

function ProductItem({ product, ...other }) {
  return (
    <Stack direction="row" alignItems="flex-start" {...other}>
      <Image
        src={product.coverUrl}
        sx={{
          mr: 2,
          width: 64,
          height: 64,
          flexShrink: 0,
          borderRadius: 1.5,
          bgcolor: 'background.neutral',
        }}
      />

      <Stack flexGrow={1}>
        <TextMaxLine variant="body2" line={1} sx={{ fontWeight: 'fontWeightMedium' }}>
          {product.name}
        </TextMaxLine>

        <Typography variant="subtitle2" sx={{ mt: 0.5, mb: 1.5 }}>
          {fCurrency(product.price)}
        </Typography>

        <TextField
          select
          size="small"
          variant="outlined"
          SelectProps={{
            native: true,
          }}
          sx={{ width: 80 }}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </TextField>
      </Stack>

      <IconButton>
        <Iconify icon="carbon:trash-can" />
      </IconButton>
    </Stack>
  );
}

ProductItem.propTypes = {
  product: PropTypes.shape({
    coverUrl: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
  }),
};

// ----------------------------------------------------------------------

function Row({ label, value, sx, valueSx = null, ...other }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ typography: 'subtitle2', ...sx }}
      {...other}
    >
      <Box component="span" sx={{ typography: 'body2' }}>
        {label}
      </Box>
      <Box sx={valueSx}>
        {value}
      </Box>
    </Stack>
  );
}

Row.propTypes = {
  label: PropTypes.string,
  sx: PropTypes.object,
  valueSx: PropTypes.object,
  value: PropTypes.string,
};
