'use client';

import { Iconify, Image } from 'src/components/landing';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { Container, Divider, StepLabel } from '@mui/material';
import FormProvider from 'src/components/hook-form';
import EcommerceCheckoutCount from 'src/sections/order/ecommerce-checkout-count';
import EcommerceCheckoutPersonalDetails from 'src/sections/order/ecommerce-checkout-personal-details';
import EcommerceCheckoutShippingMethod from 'src/sections/order/ecommerce-checkout-shipping-method';
import EcommerceCheckoutOrderSummary from 'src/sections/order/ecommerce-checkout-order-summary';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import { useResponsive } from 'src/hooks/use-responsive';
import { bgGradient } from 'src/theme/css';
import Link from 'src/components/link/link';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import { scrollToElement } from '../../../utils/general';
import { axiosPost } from '../../../utils/axios';
import { useSnackbar } from '../../../components/snackbar';
import { isValidJson } from '../../../utils/validator';
import EcommerceProduct from '../ecommerce-product';

// ----------------------------------------------------------------------

const COUNT_OPTIONS = [
  {
    label: 'Entrepreneur',
    value: 'sm',
    description: '100-199 bottles/cans',
    // price: 9.5,
  },
  {
    label: 'Startup',
    value: 'md',
    description: '200-499 bottles/cans',
    // price: 7.5,
  },
  {
    label: 'Enterprice',
    value: 'lg',
    description: '500-999 bottles/cans',
    // price: 6.5,
  },
  {
    label: 'Unicorn',
    value: 'xl',
    description: '1000+ bottles/cans',
    // price: 5.5,
  },
];

const SHIPPING_OPTIONS = [
  {
    label: '3-6 Months delivery',
    value: 'free',
    description: 'We got a solid timeline (for once)',
    // price: 0,
  },
  {
    label: '1-2 Months delivery',
    value: 'standard',
    description: 'It’s getting closer',
    // price: 320,
  },
  {
    label: '2-4 Weeks delivery',
    value: 'express',
    description: 'We are in a bit of a hurry',
    // price: 780,
  },
  {
    label: '1-14 Days delivery',
    value: 'instant',
    description: 'Ooops, this is a tight one!',
    // price: 1450,
  },
];

OrderView.propTypes = {
  isHeroVisible: PropTypes.bool,
  client: PropTypes.object,
  craftProducts: PropTypes.array,
};

export default function OrderView({ isHeroVisible = true, client = null, craftProducts = [] }) {
  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');
  const { enqueueSnackbar } = useSnackbar();

  const [step, setStep] = useState(isHeroVisible ? 0 : 1);
  const [selectedCraftProduct, setSelectedCraftProduct] = useState(craftProducts?.[0]);

  const craftProductPrices = isValidJson(selectedCraftProduct?.prices) ? JSON.parse(selectedCraftProduct?.prices) : null;

  const EcommerceCheckoutSchema = Yup.object().shape({
    // firstName: Yup.string().required('First name is required'),
    // lastName: Yup.string().required('Last name is required'),
    // email: Yup.string().required('Email address is required'),
    // phoneNumber: Yup.string().required('Phone number is required'),
    // address: Yup.string().required('Street address is required'),
    // city: Yup.string().required('City is required'),
    // zip: Yup.string().required('Zip code is required'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    zip: '',
    city: '',
    country: '',
    magnitude: 'md',
    shipping: 'free',
    craftProductId: selectedCraftProduct?.id,
    orderAmount: '',
    discountCode: ''
  };

  const methods = useForm({
    resolver: yupResolver(EcommerceCheckoutSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const craftProductId = watch('craftProductId');
  const email = watch('email');

  useEffect(() => {
    setSelectedCraftProduct(craftProducts.find((product) => +product.id === +craftProductId));
  }, [craftProductId, craftProducts]);

  const onSubmit = handleSubmit(async (data) => {
    const url = `/craft-order/create`;

    try {
      await axiosPost(url, data);
      reset();


      enqueueSnackbar('Submit successful!');
      // router.push(paths.eCommerce.orderCompleted);
    } catch (error) {
      console.error(error);
    }
  });

  const handleNext = (nextStep) => {
    setStep(nextStep);
    setTimeout(() => {
      const element = document.getElementById(`step-${nextStep}`);
      if (element) {
        scrollToElement(element);
      }
    });
  };

  const renderContantBar = <Box
    sx={{
      ...bgGradient({
        color: alpha(theme.palette.background.default, 0.9),
        imgUrl: 'https://zone-ui.vercel.app/assets/background/overlay_1.jpg',
      }),
    }}
  >
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        height: { xs: 64, md: 72 },
      }}
    >

      {mdUp ?
        <Typography variant="subtitle2">
          If you have any questions, contact us as{' '}
          <Link href="mailto:sales@beermybrand.com" target="_blank" rel="noopener">sales@beermybrand.com</Link>!
        </Typography>
        : <Typography variant="body2" sx={{ lineHeight: 1.3, pr: 1 }}>
          If you have any questions, contact us as{' '}
          <Link href="mailto:sales@beermybrand.com" target="_blank" rel="noopener">sales@beermybrand.com</Link>
        </Typography>}

      <Stack
        spacing={2.5}
        direction="row"
        alignItems="center"
        flexGrow={1}
        justifyContent="flex-end"
      >

        <Badge badgeContent={0} color="primary">
          <IconButton
            size="small"
            color="inherit"
            sx={{ p: 0 }}
          >
            <Iconify icon="logos:facebook" width={32} />
          </IconButton>
        </Badge>

        <Badge badgeContent={0} color="error">
          <IconButton
            size="small"
            color="inherit"
            sx={{ p: 0 }}
          >
            <Iconify icon="skill-icons:instagram" width={32} />
          </IconButton>
        </Badge>
      </Stack>
    </Container>
  </Box>;


  const renderHero = <Grid container spacing={3} justifyContent="space-between" alignItems="center"
                           sx={{ pt: 5, pb: { xs: 0, md: 5 }, mb: { xs: 0, md: 5 } }}>
    <Grid
      xs={12}
      md={6}
      lg={6}
      sx={{
        textAlign: { xs: 'center', md: 'left' },
      }}
    >
      <Typography variant="h1">
        Craft. Connect. Celebrate.
      </Typography>

      <Typography sx={{ mt: 3, mb: 5, color: 'text.secondary' }}>
        Crafted for marketing and event professionals.
        Order bespoke branded beer.
        Elevate your brand effortlessly.
        <br />
        <br />
        <Button
          color="primary"
          variant="contained"
          size="large"
          startIcon={
            <Iconify icon="solar:alt-arrow-down-bold" width={24} sx={{ ml: -0.5 }} />
          }
          sx={{ px: 3 }}
          onClick={() => handleNext(1)}
        >
          Start Now
        </Button>
      </Typography>
    </Grid>

    {mdUp && (
      <Grid xs={12} md={6} lg={5}>
        <Image alt="teams" src="https://zone-ui.vercel.app/assets/illustrations/illustration_teams.svg" />
      </Grid>
    )}
  </Grid>;

  const renderForm = <FormProvider methods={methods} onSubmit={onSubmit}>
    <Grid container spacing={{ xs: 5, md: 8 }}>
      <Grid xs={12} md={8}>
        <Stack spacing={5} divider={<Divider sx={{ borderStyle: 'dashed' }} />} sx={{ pb: 0.125 }}>
          {step > 0 && <div id="step-1">
            <StepLabel title="Design" step="1" />

            <Box sx={{ position: 'relative' }}>
              <Box sx={{ position: 'absolute', top: 12, right: 12, zIndex: 1 }}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  disabled
                >
                  Edit
                </LoadingButton>
              </Box>
              <Image src="/assets/images/process/mockup-1.jpg" alt="Beer My Brand" ratio="16/9" sx={{ borderRadius: 1 }} />
            </Box>
            {step === 1 && <Stack justifyContent="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                variant="contained"
                color="primary"
                size="large"
                sx={{ marginLeft: 'auto', px: 3 }}
                onClick={() => handleNext(craftProducts?.length > 0 ? 2 : 3)}
                startIcon={
                  <Iconify icon="solar:alt-arrow-down-bold" width={24} sx={{ ml: -0.5 }} />
                }
              >
                Next
              </LoadingButton>
            </Stack>}
          </div>}

          {step > 1 && craftProducts?.length > 0 && <div id="step-2">
            <StepLabel title="Select Magnitude" step="2" />

            <EcommerceProduct options={craftProducts} />
            {step === 2 && <Stack justifyContent="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                variant="contained"
                color="primary"
                size="large"
                sx={{ marginLeft: 'auto', px: 3 }}
                onClick={() => handleNext(3)}
                startIcon={
                  <Iconify icon="solar:alt-arrow-down-bold" width={24} sx={{ ml: -0.5 }} />
                }
              >
                Next
              </LoadingButton>
            </Stack>}
          </div>}

          {step > 2 && <div id="step-3">
            <StepLabel title="Select Magnitude" step="2" />

            <EcommerceCheckoutCount options={COUNT_OPTIONS} prices={craftProductPrices} />
            {step === 3 && <Stack justifyContent="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                variant="contained"
                color="primary"
                size="large"
                sx={{ marginLeft: 'auto', px: 3 }}
                onClick={() => handleNext(4)}
                startIcon={
                  <Iconify icon="solar:alt-arrow-down-bold" width={24} sx={{ ml: -0.5 }} />
                }
              >
                Next
              </LoadingButton>
            </Stack>}
          </div>}

          {step > 3 && <div id="step-4">
            <StepLabel title="Choose Priority" step="4" />
            <EcommerceCheckoutShippingMethod options={SHIPPING_OPTIONS} />
            {step === 4 && <Stack justifyContent="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                variant="contained"
                color="primary"
                size="large"
                sx={{ marginLeft: 'auto', px: 3 }}
                onClick={() => handleNext(5)}
                startIcon={
                  <Iconify icon="solar:alt-arrow-down-bold" width={24} sx={{ ml: -0.5 }} />
                }
              >
                Next
              </LoadingButton>
            </Stack>}
          </div>}

          {step > 4 && <div id="step-3">
            <StepLabel title="Set Personal Details" step="3" />
            <EcommerceCheckoutPersonalDetails />
          </div>}
        </Stack>
      </Grid>

      <Grid xs={12} md={4}>
        <EcommerceCheckoutOrderSummary
          client={client}
          tax={24}
          total={1984}
          subtotal={1800}
          shipping={-200}
          discount={279}
          // products={_products.slice(0, 3)}
          loading={isSubmitting}
          step={step}
          disabled={!email}
        />
      </Grid>
    </Grid>
  </FormProvider>;

  return (<>
      <Container
        sx={{
          overflow: 'hidden',
          pt: { xs: 1, md: 5 },
          pb: { xs: 5, md: 15 },
        }}
      >
        {isHeroVisible && renderHero}
        {renderForm}
      </Container>
      {renderContantBar}
    </>
  );
}
