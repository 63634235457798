/* eslint-disable no-return-assign */

export function copyToClipboard(str) {
  navigator.clipboard.writeText(str);
}

export function removeNulls(object) {
  return Object.entries(object).reduce((a, [k, v]) => (v === null ? a : (a[k] = v, a)), {});
}

export function getTotalValue(dataFiltered) {
  let totalValue = 0;
  let currency = null;
  let hasMultipleCurrencies = false;

  dataFiltered.forEach(({ totalValue: itemTotalValue, currency: itemCurrency }) => {
    totalValue += itemTotalValue;
    if (currency && itemCurrency && currency !== itemCurrency) {
      hasMultipleCurrencies = true;
    }
    currency = itemCurrency;
  });

  return { value: totalValue, currency: !hasMultipleCurrencies && currency };
}

export function scrollToElement(element) {
  if (!isElementInView(element)) {
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
  }
}

export function isElementInView(element) {
  const bounding = element.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
